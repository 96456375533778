import LogRocket from "logrocket";
import type { UserAttr } from "~/services/users.server";

const setupLogRocket = () => {
  if (
    window.ENV.NODE_ENV === "production" &&
    window.ENV.BITBUCKET_COMMIT &&
    window.ENV.LOGROCKET_APP_ID
  ) {
    LogRocket.init(window.ENV.LOGROCKET_APP_ID, {
      release: window.ENV.BITBUCKET_COMMIT,
    });
  }
};

const identifyLogRocketUser = (
  user: Pick<
    UserAttr,
    | "id"
    | "email"
    | "lastName"
    | "firstName"
    | "businessName"
    | "subscriptionType"
    | "userName"
    | "advanced"
  > & { role: UserAttr["roleType"] },
) => {
  if (
    window.ENV.NODE_ENV === "production" &&
    window.ENV.BITBUCKET_COMMIT &&
    window.ENV.LOGROCKET_APP_ID
  ) {
    LogRocket.identify(user.id, {
      advanced: user.advanced,
      businessName: user.businessName,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      role: user.role,
      subscriptionType: user.subscriptionType,
      userName: user.userName,
    });
  }
};

export { setupLogRocket, identifyLogRocketUser };
